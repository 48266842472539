<template>
  <div class="ml-categories">
    <div class="lottie-icon">
      <div ref="lottieml" class="lottie-ml" />
    </div>

    <div class="arrow" />
    <div class="tooltip-container">
      <div class="tooltip-wrapper">
        <span>Il s'agit peut être :</span>
        <div style="margin-top: 10px">
          <slot />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import lottieWeb from "lottie-web/build/player/lottie_light.js";
import mlComptalibAnimationData from "@/assets/animations/comptalib-ml-suggestion.json";
import mlAnimationData from "@/assets/animations/ml-suggestion.json";

const { isComptalib } = useBrandsComposable();

const animationML = ref<any>(null);
const lottieml = ref<HTMLElement | null>(null);

onMounted(() => {
  if (!import.meta.server) {
    if (isDefined(lottieWeb) && lottieml.value) {
      animationML.value = lottieWeb.loadAnimation({
        // @ts-expect-error
        container: lottieml.value as HTMLElement,
        renderer: "svg",
        loop: false,
        autoplay: true,
        animationData: isComptalib() ? mlComptalibAnimationData : mlAnimationData,
      });
    }
  }
});
</script>

<style lang="scss" scoped>
.ml-categories {
  display: flex;
  height: auto;
  margin: $uds-spacing-1_5 0;

  &.--padding {
    padding: 0 $uds-spacing-1_5;
  }

  .lottie-icon {
    display: flex;
    margin-top: 2px;
    .lottie-ml {
      height: 32px;
      width: 32px;

      :deep(svg g g g g path) {
        fill: $uds-white !important;
      }
      :deep(svg g g g g g path) {
        fill: $uds-white !important;
      }
    }
  }

  .arrow {
    height: 2px;
    left: 5px;
    top: $uds-spacing-1;
    position: relative;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-right: 10px solid $uds-neutral-200;
  }

  .tooltip-container {
    flex-grow: 1;
    background: $uds-neutral-200;
    margin-left: 3px;
    border-radius: $uds-radius-2;
    height: auto;

    .tooltip-wrapper {
      padding: $uds-spacing-1 $uds-spacing-2;
      span {
        font-size: 16px;
        font-weight: 100;
        color: $uds-neutral-900;
      }
    }
  }
}
</style>
